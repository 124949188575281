'use strict';

var $TypeError = require('es-errors/type');
var inspect = require('object-inspect');
var IsPropertyKey = require('./IsPropertyKey');
var Type = require('./Type');

// https://262.ecma-international.org/6.0/#sec-get-o-p

module.exports = function Get(O, P) {
  // 7.3.1.1
  if (Type(O) !== 'Object') {
    throw new $TypeError('Assertion failed: Type(O) is not Object');
  }
  // 7.3.1.2
  if (!IsPropertyKey(P)) {
    throw new $TypeError('Assertion failed: IsPropertyKey(P) is not true, got ' + inspect(P));
  }
  // 7.3.1.3
  return O[P];
};