module.exports = function properlyBoxed(method) {
  // Check node 0.6.21 bug where third parameter is not boxed
  var properlyBoxesNonStrict = true;
  var properlyBoxesStrict = true;
  var threwException = false;
  if (typeof method === 'function') {
    try {
      // eslint-disable-next-line max-params
      method.call('f', function (_, __, O) {
        if (typeof O !== 'object') {
          properlyBoxesNonStrict = false;
        }
      });
      method.call([null], function () {
        'use strict';

        properlyBoxesStrict = typeof this === 'string'; // eslint-disable-line no-invalid-this
      }, 'x');
    } catch (e) {
      threwException = true;
    }
    return !threwException && properlyBoxesNonStrict && properlyBoxesStrict;
  }
  return false;
};