'use strict';

var $TypeError = require('es-errors/type');
var hasOwn = require('hasown');
var isPropertyDescriptor = require('../helpers/records/property-descriptor');

// https://262.ecma-international.org/5.1/#sec-8.10.1

module.exports = function IsAccessorDescriptor(Desc) {
  if (typeof Desc === 'undefined') {
    return false;
  }
  if (!isPropertyDescriptor(Desc)) {
    throw new $TypeError('Assertion failed: `Desc` must be a Property Descriptor');
  }
  if (!hasOwn(Desc, '[[Get]]') && !hasOwn(Desc, '[[Set]]')) {
    return false;
  }
  return true;
};