'use strict';

var $TypeError = require('es-errors/type');
var IsAccessorDescriptor = require('./IsAccessorDescriptor');
var IsDataDescriptor = require('./IsDataDescriptor');
var isPropertyDescriptor = require('../helpers/records/property-descriptor');

// https://262.ecma-international.org/6.0/#sec-isgenericdescriptor

module.exports = function IsGenericDescriptor(Desc) {
  if (typeof Desc === 'undefined') {
    return false;
  }
  if (!isPropertyDescriptor(Desc)) {
    throw new $TypeError('Assertion failed: `Desc` must be a Property Descriptor');
  }
  if (!IsAccessorDescriptor(Desc) && !IsDataDescriptor(Desc)) {
    return true;
  }
  return false;
};