'use strict';

var implementation = require('./implementation');
var zeroWidthSpace = '\u200b';
var mongolianVowelSeparator = '\u180E';
module.exports = function getPolyfill() {
  if (String.prototype.trim && zeroWidthSpace.trim() === zeroWidthSpace && mongolianVowelSeparator.trim() === mongolianVowelSeparator && ('_' + mongolianVowelSeparator).trim() === '_' + mongolianVowelSeparator && (mongolianVowelSeparator + '_').trim() === mongolianVowelSeparator + '_') {
    return String.prototype.trim;
  }
  return implementation;
};